.fc .fc-toolbar.fc-header-toolbar {
  margin-top: 0.3rem;
  margin-bottom: 0.7rem;
}

/* .fc .fc-button-primary {
  background-color: #689f38;
  border: none;
} */

/* .fc .fc-button-primary:disabled {} */

.fc .fc-toolbar-title {
  font-size: 1.4em;
}

/* .fc .fc-daygrid-day-top {
  color: rgba(0, 0, 0, 0);
} */

/* Googleカレンダーで取得した祝日の説明を非表示にする */
.fc .google-holiday .fc-event-title {
  color: rgba(0, 0, 0, 0);
}
